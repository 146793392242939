import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import ProfilePicF from "../Assets/john-doe-image-f.png";
import { AiFillStar } from "react-icons/ai";
import { TestimonialData } from "../data";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper" id="Testimonials">
      <div className="work-section-top">
        <p className="primary-subheading">{TestimonialData.title}</p>
        <h1 className="primary-heading">{TestimonialData.title2}</h1>
        <p className="primary-text">{TestimonialData.desc}</p>
      </div>
      <div className="testimonial sliderTisto">
        <div className="slide-track-test">
    {TestimonialData.reviews.map((rev)=>(

      <figure className="snip1157 slideTest">
  <blockquote dir="RTL">{rev.review}
    <div className="arrow"></div>
  </blockquote>
   <img  src={rev.url_profile} dir="RTL" alt="sq-sample3" />
   <div className="author"> 
    <h5 dir="RTL" >{rev.name} <span> {rev.profission}</span></h5>
  </div>

</figure>
)
    
    )}

</div>

      </div>
    </div>
  );
};

export default Testimonial;