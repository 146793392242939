import React from "react";
import AboutBackground from "../Assets/about-background.png";

import AutoDeskProducts from "../Assets/AutoDeskProducts.jpg";
import HomeBannerImage from "../Assets/home-banner-image.png";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { about_data,call_to_action_url, products_links } from "../data";


const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={HomeBannerImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p dir="RTL" className="primary-subheading"></p>
        <h1 dir="RTL" className="primary-heading">{about_data["features"]}
      </h1>
        <p dir="RTL" className="primary-text">
          {about_data["features_desc"]}
           </p>
        <p dir="RTL" className="primary-text">
          {about_data["features_desc2"]}
           </p>
        <p dir="RTL" className="primary-text">
          {about_data["bulletpointTitle"]}
           </p>
       <ol dir="RTL" className="primary-text" >
    {about_data.bulletPoints.map((bullet)=><li dir="RTL"> {bullet.titre}</li>)}
       </ol>
        <h3 dir="RTL" className="primary-heading">{about_data["applications_title"]}
      </h3>
      <p dir="RTL" className="primary-text">
          {about_data["applications_desc"]}
           </p>
        <img src={AutoDeskProducts} alt="" className="img_prod" />
        <p dir="RTL" className="primary-text">
          {about_data["application_bullet_title"]}
           </p>
           <ul dir="RTL" className="primary-text" >
    {about_data.applications.map((bullet)=><li dir="RTL"> {bullet.titre}</li>)}
       </ul>

        <div className="about-buttons-container">
        <div class="slider" onMouseDown={(e)=>{
          e.preventDefault();
          window.location.href=call_to_action_url.whatsapp;
          }}>
	<div class="slide-track">
    {
      products_links.map((item,index) => (
        <div class="slide" >
			<img src={item.link} height="100" width="250" alt="" />
		</div>))
      
    }
		
		
	</div>
</div>

        </div>
        <div className="about-buttons-container">
         <a href={call_to_action_url.whatsapp}>
           <button className="secondary-button">{about_data["call_to_action"]}</button> </a>
           {/* <a href={call_to_action_url.telegram}>  <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Free Courses
          </button></a> */}
         
        </div>
      </div>
    </div>
  );
};

export default About;