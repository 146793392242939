import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import AutodeskLogo from "../Assets/autodesk-logo.png";
import Navbar from "./Navbar";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { home_data,call_to_action_url } from "../data";

const Home = () => {
  return (
    <div className="home-container" id="#Home">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 dir="RTL" className="primary-heading">{home_data["title"]}
          </h1>
          <p dir="RTL" className="primary-text">
          {home_data["title2"]}
.
          </p>
          <p  dir="RTL" className="primary-text">
          {home_data["miniAds"]}
.
          </p>
          <a href={call_to_action_url.whatsapp}><button className="secondary-button">
            {home_data["call_to_action"]} <WhatsAppIcon />{" "}
          </button></a>
        </div>
        <div className="home-image-section">
          <img src={AutodeskLogo} alt="" />
        </div>
      </div>
      <a className="whats-app" href={call_to_action_url.whatsapp} target="_blank">
 {home_data["call_to_action"]} <WhatsAppIcon/> 
</a>
    </div>
  );
};

export default Home;