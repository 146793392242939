import React from "react";
import ContactUs from "../Assets/whatsapp.png";
import ChooseMeals from "../Assets/choose-image.png";
import DeliveryMeals from "../Assets/delivery-image.png";
import { Q_and_A,call_to_action_url } from "../data";

const Work = () => {
 
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading" id="Questions" >{Q_and_A.title}</p>
        {Q_and_A.questions.map((question)=>{
          return(<>
          <h1 dir="RTL" className="primary-heading">{question.question}</h1>
          <ul dir="RTL" className="primary-text">
            {question.answer.map((answer,index)=>{
              return <li key={index} dir="RTL" className="primary-text">{answer.text}</li>;
            })}
        
        
        </ul>
      </>
                 
          );
        })}
     
        </div>
      
    </div>
  );
};

export default Work;