export const langs =[
    {name :"en", url:"/"},
    {name :"fr", url:"/fr/"},
    {name :"ar", url:"/ar/"},
  
]


export const home_data={
    title: " اشتراك أوتوديسك \nAutodesk\n رخيص",
    title2:"الوصول الكامل إلى 46 برنامج",
    miniAds:"حصرياً  شراء اشتراك أوتوديسك  رخيص من متجرنا  \nو وفير 69% من السعر الأصلي.",
    call_to_action:"اطلب الأن"
}
export const about_data={
    features: "ما يمكن فعله باستخدام Autodesk؟",
    features_desc:"مع استمرار تطور مجموعة حلول برامج Autodesk، فإن حالات الاستخدام المتاحة لأصحاب الأعمال تتزايد باستمرار. تم تصميم كل برنامج في بيئة أوتوديسك خصيصًا لتلبية احتياجات معينة. على سبيل المثال، يقوم حل Maya بإنشاء رسوم متحركة وعوالم فريدة من نوعها في مجال الترفيه والوسائط. يقدم Inventor أداة ملائمة للمصممين المحترفين، بينما يعمل Revit على تمكين المهندسين المعماريين والمهندسين",
    features_desc2:"في حين أن جميع الأدوات التي صممتها أوتوديسك لها وظائفها الخاصة، إلا أنها توفر مستوى ثابتًا من البساطة وسهولة الاستخدام. يتم تحديث الحلول بشكل متكرر لتناسب احتياجات المحترفين المتطورين، وتأتي جميعها مع وثائق وأدلة لمساعدة المستخدمين على البدء.",
    bulletpointTitle:"مع اشتراك أوتوديسك Autodesk يمكن القيام بالتالي:" ,
    bulletPoints:[
        {titre:"إنشاء رسوم متحركة وتصميمات لبيئات XR."},
        {titre:" تصميم عمليات المحاكاة الافتراضية وسير العمل."},
        {titre:"إنشاء إصدارات افتراضية من المنتجات والأفكار."},
        {titre:"التعاون في تصميم المنتج أو الحل."},
        {titre:"تبسيط عمليات التطوير والهندسة"},
    ],
    applications_title: "التطبيقات المتوفرة في اشتراك أوتوديسك",
    applications_desc: "هل سبق لك أن تساءلت عن عدد عناوين المنتجات التي تمتلكها Autodesk في مجموعة برامج التصميم الخاصة بها؟ حسنًا، إنه 112 في هذا الوقت، ولكن مع عمليات الاستحواذ المستمرة، فإن هذا العدد يتزايد باستمرار. التصميم والنماذج الرقمية ونمذجة معلومات البناء (BIM) وما إلى ذلك……. تغطي الأدوات التي أنشأتها Autodesk قطاعات صناعية متعددة ويستخدمها أكثر من 10 ملايين مستخدم في أكثر من 185 دولة.",
    application_bullet_title:"ونذكر من هذه القائمة أشهر برامج أوتوديسك التي يستخدمها أكبر عدد من العملاء:",
    applications:[
        {titre:" أوتوكاد AutoCAD."},
        {titre:"Autodesk Revit"},
        {titre:"اشتراك 3ds Max"},
        {titre:"Autodesk Inventor"},
        {titre:"AutoCAD Civil 3D"},
        {titre:"اشتراك برنامج Maya"},
        {titre:"AutoCAD Architecture"},
        {titre:"Autodesk AutoCAD for Mac"},
        {titre:"برنامج Navisworks"},

    ],
    call_to_action:"لديك استفسار؟"

   
    

}

export const call_to_action_url={
    title:"اسئلة متداولة ",
    whatsapp:"https://wa.me/212614783706?text='Hey ,i want to buy a autodesk software'",
    telegram:"https://t.me/eddigitals",
    facebook:"",
    twitter:"",
    trends:"",

}

export const Q_and_A={
    title : "اسئلة متداولة ",
    questions:[
        {
    question:"لماذا تشتري أوتوديسك من متجرنا؟",
    answer :[ 
        {text:"نوفر أفضل الخدمات الإرضاء عملائنا، وهذا هدفنا الرئيسي"},
        {text:"حن نعمل في هذا المجال لأكثر من 4 سنوات ولدينا سمعة طيبة."},
        {text:"لدينا فريق دعم عملاء متاح على مدار الساعة طوال أيام الأسبوع."},
        {text:"نحاول دائمًا توفير أقل الأسعار من المتاجر الأخرى."},
        {text:"موعد التفعيل سريع من ساعة إلى 6 ساعات بالكثير."},
       

],
    type:"bulletpoint"},
        {
    question:"كيفية الحصول على اشتراك Autodesk رخيص",
    answer : [ 
        {text:"بمجرد الشراء ودفع رسوم اشتراك سوف نتلقى طلبك على الفور، سيتم العمل عليه بنفس الوقت."},
        {text:"بعدها سوف يتم تفعيل اشتراك أوتوديسك Autodesk وكل البرامج على حسابك الشخصي."},
        {text:"يتم تفعيل الخدمة بحسابك و تسليم إلكترونياً في غضون 6 ساعات بعد تلقي تفاصيل الطلب."},


    ],

    type:""},
        {
    question:"	⏳ مده تسليم الطلب؟	.",
    answer : [ 
        {text:"المنتجات في متجر بريميوم بعد الدفع يتم تسليم بشكل تلقائيا, ولكن في بعض الاحيان يحتاج الامر الى تسليم بشكل يدوي."},
        {text:"تسليم الطلبات يعتمد على حسب نوع الاشتراك وبالعادة لا يزيد عن 5 ساعات خلال أوقات العمل."},
       


    ],

    type:""},
        
        {
    question:"⌚️ ماهي اوقات العمل؟",
    answer : [ 
        {text:"اوقات العمل من ساعه 9 صباحاً الى ساعه 9 ليلاً بتوقيت السعودية, ويتم بدء في تنفيذ بعض الطلبات من ساعه 12 صباحاً بتوقيت السعودية."},

    ],

    type:""},
        

    ]
    

}
export const TestimonialData={
    title2:"ماذا يقول زبائنا ؟",
    title:"زبائننا",
    desc:"ماذا يقول زبائنا؟ اكتشف تجاربهم الرائعة مع EdDigitals. شاركوا اللحظات السلسة في اختيار وشراء منتجات Autodesk واستمتعوا بخدمتنا. اطلع على شهاداتهم واستلهم. رضاكم هو نجاحنا!",
    subtitle:"",
    reviews:[
        {
            name:"ساير محمد",
            profission:"مصمم جرافيك",
            review:"ساير: لا يمكنني إلا أن أشيد بـ EdDigitals! سهولة شراء منتجات Autodesk ودعمهم المميز يجعلانهم الخيار الأمثل لاحتياجاتي.",
            url_profile:"https://media.istockphoto.com/id/1309489745/photo/portrait-of-young-happy-indian-business-man-executive-looking-at-camera-eastern-male.jpg?s=612x612&w=0&k=20&c=2hQ4Sm9Puyf1otnVwcyvnY0fk4af-XxHyGqq9ozPpgE=",

        },
        {
            name:"صفاء حسن",
            profission:"مهندسة معمارية",
            review:"صفاء: EdDigitals هو ملاذي لمشتريات Autodesk. العملية السلسة والتوصيل السريع يجعلانني أركز على ابتكاري دون أي تعقيد.",
            url_profile:"https://media.istockphoto.com/id/1394149744/photo/headshot-of-early-20s-middle-eastern-woman.jpg?s=612x612&w=0&k=20&c=Q4gBjPUfikbPtkFh3I9_CoLF53H8Bz9FAfxiMOO7eIY=",

        },
        {
            name:"عبدالله علي",
            profission:"مهندس ميكانيكي",
            review:"عبدالله: إعجابي بالتزام EdDigitals تجاه رضا العملاء. تنوع وسائل الدفع جعل عملية الشراء مريحة، وأنا مسرور بشكل كبير ببرمجيات Autodesk.",
            url_profile:"https://media.istockphoto.com/id/167442618/photo/serious-man.jpg?s=612x612&w=0&k=20&c=kewPyjuzyS29cI8lkM_MYO-WJBtZr6joLd1977SPzhE=",

        },
        {
            name:"لينا محمود",
            profission:"مصممة داخلية",
            review:"لينا: تسليم التميز! من اختيار باقة Autodesk المناسبة إلى توصيل المنتج بسرعة، خدمتهم لا تقدم إلا الأفضل. عميل راضٍ بكل تأكيد!",
            url_profile:"https://media.istockphoto.com/id/1473711199/photo/face-portrait-student-and-man-in-university-ready-for-back-to-school-learning-goals-or.jpg?s=612x612&w=0&k=20&c=Xrwp5ePvm6RjixgAjJo-OAw9oXkLt_HcmT3bdlLZUdw=",

        },
        {
            name:"يوسف عبدالله",
            profission:" مهندس برمجيات",
            review:"يوسف: تحية لـ EdDigitals! الدعم الشخصي في اختيار منتج Autodesk كان رائعاً. كانت العملية كلها سلسة، وأنا سعيد بشرائي",
            url_profile:"https://media.istockphoto.com/id/492789232/photo/content-young-arab-man-in-traditional-clothing.jpg?s=612x612&w=0&k=20&c=BmsD-FMVfZPqj5jndy3L_jOo6S-sTIZhsH2LCJmc7To=",

        },

        {
            name:"هالة أحمد",
            profission:"رائدة أعمال",
            review:"هالة: يفهم EdDigitals احتياجات الشركات. تنوع منتجات Autodesk وسهولة الشراء يجعلونهم الشريك المثالي لأي شركة",
            url_profile:"https://media.istockphoto.com/id/1163306517/photo/happy-successful-businesswoman-smiling-at-camera.jpg?s=612x612&w=0&k=20&c=Imb_CPf70upPoAWZo9QrlQGIRcE86WeKUWPRX489G90=",

        },

        {
            name:"أحمد عادل",
            profission:"مهندس كهرباء",
            review:"أحمد: سريعة، موثوقة، وفعّالة - هكذا هو EdDigitals! اشتريت Autodesk من خلالهم، وكانت العملية سلسة. لا يمكنني إلا أن أكون سعيداً بالخدمة.",
            url_profile:"https://media.istockphoto.com/id/1090878400/photo/portrait-of-smiling-handsome-man-in-blue-polo-shirt-standing-with-crossed-arms-isolated-on.jpg?s=612x612&w=0&k=20&c=dY3vmGskILbnWT_U3OcGqNdJ4Mg464qE9SFhRYF6TQ8=",

        },
        {
            name:"رنا محمد",
            profission:"مدير تسويق",
            review:"رنا: EdDigitals جعلوني أشعر بأنني عميل مهم. الاستجابة السريعة لاستفساراتي وعملية الشراء المباشرة فاقت توقعاتي. خدمة رائعة!"            ,
            url_profile:"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg",

        },
        {
            name:"محمد حسن",
            profission:"مهندس معماري",
            review:"محمد: عميل متكرر بسبب الاستمرارية في EdDigitals. التزامهم بالجودة ورضا العملاء يتألق في كل خطوة من عملية شراء Autodesk.",
            url_profile:"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg",

        },
    
        {
            name:"نور الدين",
            profission:"مدير مشاريع",
            review:"نور: EdDigitals يزيل الضغط من عمليات شراء منتجات Autodesk. العملية السلسة والخدمة العملاء الممتازة تجعلونهم خياري الأول لاحتياجات البرمجيات.",
            url_profile:"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg",

        },
    ]

}
export const products_links=[
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/autocad-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/autocad-revit-lt-suite-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/fusion-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/revit-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/civil-3d-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/architecture-engineering-construction-collection-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/maya-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/3ds-max-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/shotgrid-2022-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/product-design-manufacturing-collection-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/products/bim-collaborate/lockups/bim-collaborate-pro-2022-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/revit-lt-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/navisworks-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/flame-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/arnold-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/recap-pro-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/autodesk-build-2022-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/fusion-team-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/fusion-manufacturing-extension-2025-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/fusion-simulation-extension-2025-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/fusion-design-extension-2025-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/fusion-manage-extension-2025-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/fusion-signal-integrity-extension-2025-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/fusion-360-for-manufacturing-2023-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/infraworks-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/netfabb-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/mudbox-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/advance-steel-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/autodesk-cfd-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/fabrication-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/design-review-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/dwg-trueview-lockup-610x66.png'},
    {link:'https://damassets.autodesk.net/content/dam/autodesk/www/product-imagery/lockup-610x66/factory-design-utilities-lockup-610x66.png'},



]