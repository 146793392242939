import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Contact = () => {
  return (
<div className="contact-page-wrapper" id="Contact">
  <h1 className="primary-heading">هل لديك سؤال؟</h1>
  <h1 className="primary-text">دعنا  نساعدك</h1>
  
  <a href="https://wa.me/212614783706?text='مرحبًا، أرغب في شراء برنامج Autodesk'">
    <button className="secondary-button">
      اتصل بنا <WhatsAppIcon />{" "}
    </button>
  </a>
</div>
  );
};

export default Contact;